/* You can add global styles to this file, and also import other style files */
@import '~@angular/material/prebuilt-themes/deeppurple-amber.css';
@import 'https://maxcdn.bootstrapcdn.com/font-awesome/4.7.0/css/font-awesome.min.css';


/* JBP Toast widget */
.acn-toast-main {
  right: 0 !important;
}
/* JBP Toast widget */

body.modal-open { 
  overflow:inherit; 
 padding-right:inherit !important; 
}

footer.modal-open{
  overflow:inherit; 
  padding-right:inherit !important; 
}

.index-body.main-background{
    background-color: #ebeef3;
  }

  button {
    box-shadow:none;
    -webkit-box-shadow:none;
  }

  /*JPF EA Modal Config*/
.custom-modal .mat-dialog-container {
  border-radius: 8px;
  width: 500px;
}

.create-reports .mat-dialog-container {
  border-radius: 8px;
  width: 700px;
}

.overlay-modal .mat-dialog-container{
  background: none;
  box-shadow:  none;
}

@media print {
  app-root app-holdingprogramdetailspage-options > *, app-root app-holdingprogramdetailspage-rsu > *, app-root app-multiplegrants > *, app-root app-holdingsummarypage > * { 
    display: none; 
  }

  app-root app-userinfoheader, app-root app-navheader, app-root app-pagefooter {
    display: none;
  }

  app-root app-holdingprogramdetailspage-options app-grant-print-layout, app-root app-holdingprogramdetailspage-rsu app-grant-print-layout, app-root app-multiplegrants app-grant-print-layout, app-root app-holdingsummarypage app-grant-print-layout { 
    display: block; 
  }
}

.textSize{
  font-size: 14px !important;
}